@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	/* common style*/
	a {
		@apply cursor-pointer;
	}
	body {
		@apply bg-gray-lightest;
	}
	body,
	html {
		@apply h-full;
	}
	.wrapper {
		@apply relative sm:pl-11 text-sm;
	}
	.labelValue {
		@apply relative;
	}
	.labelValue label {
		@apply text-gray-500 block mb-1;
	}
	.labelValueInline {
		@apply relative pl-24;
	}
	.labelValueInline label {
		@apply text-gray-500 absolute left-0;
	}
	.infoIcon {
		@apply text-gray-light hover:text-primary cursor-pointer;
	}
	.infoIcon > svg {
		@apply fill-current h-3 w-3 inline-block;
	}

	/*---table start---*/
	.table {
		@apply w-full border-collapse;
	}
	.table.bordered {
		@apply border border-gray-light;
	}
	.table td,
	.table th {
		@apply px-1 py-2 text-center;
	}
	.table.bordered > tbody > tr > td,
	.table.bordered > thead > tr > th {
		@apply border border-gray-light;
	}
	.table > thead > tr > th {
		@apply text-gray-medium font-semibold;
	}
	.table.striped > tbody > tr {
		@apply even:bg-white odd:bg-gray-lightest;
	}
	.table.striped > thead > tr {
		@apply bg-gray-200;
	}
	.table.striped > thead > tr > th {
		@apply bg-gray-200 text-gray-600;
	}
	.table.divider > thead tr {
		@apply border-b border-gray-medium;
	}
	.table.divider > tbody tr {
		@apply border-t border-gray-medium;
	}
	.table.small > thead > tr > th,
	.table.small > tbody > tr > td {
		@apply py-1;
	}
	.table.large > thead > tr > th,
	.table.large > tbody > tr > td {
		@apply py-2;
	}
	.table.responsive > thead {
		@apply hidden sm:table-header-group;
	}
	.table.responsive > tbody > tr {
		@apply p-4 sm:p-0;
	}
	.table.responsive > tbody > tr > td {
		@apply block sm:table-cell;
	}
	.table.noPadding > thead > tr > th:first-child,
	.table.noPadding > tbody > tr > td:first-child {
		@apply pl-0;
	}
	.table.noPadding > thead > tr > th:last-child,
	.table.noPadding > tbody > tr > td:last-child {
		@apply pr-0;
	}
	table.hover > tbody > tr:hover {
		@apply hover:bg-green-50;
	}
	/*---table end---*/

	/*---grid style---*/
	.gridWrap.striped > .grid {
		@apply odd:bg-gray-lightest;
	}
	.gridWrap.gapSm > .grid > div {
		@apply p-2;
	}
	.gridWrap.striped > .grid.head {
		@apply font-medium;
	}

	.gridWrap.bordered > .grid {
		@apply border-t border-gray-lightest;
	}

	.gridWrap.bordered > .grid.head {
		@apply font-medium border-b border-gray-lightest;
	}

	/*--modal start--*/
	.modal {
		@apply hidden;
	}
	.modal__overlay {
		@apply fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-70;
	}
	.modal__container {
		@apply bg-white w-full rounded-sm box-border m-4 max-w-screen-md relative;
	}
	.modal__container.small {
		@apply max-w-screen-sm;
	}
	.modal__container.large {
		@apply max-w-screen-lg;
	}
	.modal__container.micro {
		@apply max-w-xs;
	}
	.modal__container.transparent {
		@apply bg-transparent;
	}
	.modal__container.noMargin {
		@apply m-0;
	}
	.modalHeader {
		@apply flex items-center justify-end px-2.5 py-1;
	}
	.modalHeader.border {
		@apply border-b;
	}
	.modalTitle {
		@apply text-md text-gray-dark flex-grow;
	}
	.modalCloseBt {
		@apply w-7 h-7 flex items-center justify-center focus:outline-none border-0 text-gray-medium;
	}
	.modalCloseBt > svg {
		@apply w-3 h-3 text-gray-medium;
	}
	.modalBody {
		@apply p-2.5;
	}
	.modalFooter {
		@apply py-2.5 mt-2.5 px-2.5 text-right;
	}

	.modalFooter.border {
		@apply border-t;
	}

	@keyframes mmfadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes mmfadeOut {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}

	@keyframes mmslideIn {
		from {
			transform: translateY(15%);
		}
		to {
			transform: translateY(0);
		}
	}

	@keyframes mmslideOut {
		from {
			transform: translateY(0);
		}
		to {
			transform: translateY(15%);
		}
	}
	.micromodal-fade {
		@apply hidden;
	}

	.micromodal-fade.is-open {
		@apply block;
	}

	.micromodal-fade[aria-hidden="false"] .modal__overlay {
		animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
	}

	.micromodal-slide[aria-hidden="true"] .modal__overlay {
		animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
	}
	.micromodal-slide .modal__container,
	.micromodal-slide .modal__overlay {
		will-change: opacity;
	}

	.micromodal-slide {
		display: none;
	}

	.micromodal-slide.is-open {
		display: block;
	}

	.micromodal-slide[aria-hidden="false"] .modal__overlay {
		animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
	}

	.micromodal-slide[aria-hidden="false"] .modal__container {
		animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
	}

	.micromodal-slide[aria-hidden="true"] .modal__overlay {
		animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
	}

	.micromodal-slide[aria-hidden="true"] .modal__container {
		animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
	}
	/*--modal end--*/
	.leftNavigation {
		@apply bg-primary fixed z-5 inset-y-0 left-0 w-52 sm:w-11 transition duration-300 ease-in-out;
	}
	.leftNavigation.hide {
		@apply transform -translate-x-52 sm:translate-x-0;
	}
	.menuItems {
		@apply flex flex-1 flex-col;
	}
	.menuLink {
		@apply flex relative sm:inline-block h-10 px-4 sm:px-0 overflow-hidden hover:bg-primary-dark;
	}
	.menuLinkIcon {
		@apply relative h-10 z-1 flex items-center justify-center;
	}
	.menuLinkTitle {
		@apply sm:absolute sm:opacity-0 opacity-100 sm:left-0 sm:top-0 z-0 h-10 sm:w-24 flex items-center sm:bg-primary-dark ml-2.5 sm:ml-0 text-white text-xs py-2 pr-2;
	}
	.menuLink:hover {
		overflow: visible;
	}
	.menuLink:hover > .menuLinkTitle {
		@apply opacity-100 transform translate-x-11 transition duration-300 ease-in-out;
	}
	.menuLink:hover > .menuLinkIcon {
		@apply bg-primary-dark;
	}
	.personName {
		@apply overflow-hidden;
	}
	.personName:hover {
		@apply overflow-visible;
	}
	.personName:hover > div {
		@apply bg-primary;
	}
	.personName:hover > div > span {
		@apply bg-white text-primary;
	}

	.personName:hover > span {
		@apply opacity-100 transform translate-x-11 transition duration-300 ease-in-out;
	}
	/*nanobar*/
	.nanobar {
		@apply w-full h-1 z-100 top-0;
	}
	.nanobar > .bar {
		@apply bg-warning;
		width: 0;
		height: 70%;
		clear: both;
		transition: height 0.3s;
		/* box-shadow: 1px 0px 4px $brand-warning; */
	}
	span.flatpickr-weekday {
		color: #fff !important;
		font-weight: normal !important;
	}
	.rx {
		@apply w-4 h-4 inline-block items-center justify-center rounded-full bg-yellow-300 text-center;
	}
	.rx > svg {
		@apply h-2.5 w-2.5 inline;
	}
}
